import { SystemStyleObject } from "@styled-system/css";
import {
  GatsbyImage,
  IGatsbyImageData,
  withArtDirection,
  GatsbyImageProps,
} from "gatsby-plugin-image";
import React, { useMemo } from "react";
import { breakpoints } from "../constants/theme";
import Box from "./Box/Box";

interface Props extends Omit<GatsbyImageProps, "image"> {
  desktopImage: IGatsbyImageData;
  alt: string;
  mobileImage?: IGatsbyImageData;
  sx?: SystemStyleObject;
  imgStyle?: React.CSSProperties;
  loading?: "eager" | "lazy";
}

function GatsbyImageWithArtDirection({
  desktopImage,
  alt,
  mobileImage,
  imgStyle,
  sx,
  loading = undefined,
  ...imgProps
}: Props) {
  const image = useMemo(() => {
    if (!mobileImage) return desktopImage;
    return withArtDirection(desktopImage, [
      {
        media: `(max-width: ${breakpoints[0]})`,
        image: mobileImage,
      },
      {
        media: `(max-width: ${breakpoints[1]})`,
        image: desktopImage,
      },
      {
        media: `(max-width: ${breakpoints[2]})`,
        image: desktopImage,
      },
      {
        media: `(max-width: ${breakpoints[3]})`,
        image: desktopImage,
      },
    ]);
  }, [desktopImage, mobileImage]);

  return (
    <Box
      as={GatsbyImage}
      sx={{ ...sx }}
      {...imgProps}
      loading={loading}
      imgStyle={{ ...imgStyle }}
      image={image}
      alt={alt}
    />
  );
}

export default GatsbyImageWithArtDirection;
